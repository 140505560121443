.main_page {
	overflow-x: hidden;
}

.main_page .background {
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: calc(100vh - var(--header-height));
	background: #fff;
	background-size: 100%;
}

.content {
	height: 100%;
}

.header {
	padding: 30px 120px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* .main_block {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 50px;
} */

/* .img_block {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.img_block > img {
	width: 90%;
	height: auto;
} */

/* .title_block {
	width: 575px;
}

.title_block .title {
	margin-bottom: 16px;
	letter-spacing: -1px;
} */

/* .title_block .description {
	letter-spacing: -0.5px;
} */

.info_cases_block {
	padding: 140px 0;
	background: #fff;
}

.info_cases {
	display: flex;
	flex-direction: column;
	row-gap: 140px;
	align-items: center;
}

/* .popular_categories {
	padding: 65px 0 120px;
} */

.img_block {
	flex: 1;
	position: relative;
}

.img_block > img {
	position: absolute;
	right: -140px;
	width: 135%;
	height: auto;
}

/* .popular_categories .title {
	text-transform: uppercase;
	margin-bottom: 16px;
} */

.find_company_block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	/* padding-top: 25px; */
}

@media screen and (min-width: 1920px) {
	.img_block > img {
		right: -140px;
		width: 110%;
		height: auto;
	}
}
