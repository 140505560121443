.help_block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.help_block__title {
	color: #2c2e32;
	text-align: center;
	font-family: Exo;
	font-size: 48px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 30px;
}

.help_block__title .active {
	color: #00a5ff;
}

.help_block__descr {
	color: rgba(44, 46, 50, 0.7);
	text-align: center;
	font-family: Exo;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	margin-bottom: 60px;
}

.help_block__descr_wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}
.help_block__buttons {
	display: flex;
	margin-bottom: 120px;
	border-radius: 6px;
	background: rgba(44, 46, 50, 0.05);
	font-family: Exo;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
	padding: 5px;
}
.help_block__buttons :global(.ant-segmented-item-label) {
	padding: 16px 40px !important;
	color: rgba(44, 46, 50, 0.7);
}
.help_block__buttons :global(.ant-segmented-item-selected) {
	box-shadow: unset;
	background: #00a5ff;
}
.help_block__buttons :global(.ant-segmented-thumb) {
	background: #00a5ff;
	transition: transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
		width 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
	color: #ffffff;
	border-radius: 4px;
}

.help_block__buttons
	:global(.ant-segmented-item-selected .ant-segmented-item-label) {
	color: white;
}
/* .help_block__button {
	padding: 16px 40px;
}
.help_block__button.active {
	display: flex;
} */

.descr_block {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.descr_block__title {
	color: #2c2e32;
	font-family: Exo;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.descr_block__descr {
	color: rgba(44, 46, 50, 0.7);
	font-family: Exo;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}
