.company_card {
	display: flex;
	border: 1px solid #eff0f4;
	box-shadow: 6px 6px 20px rgba(0, 27, 107, 0.07);
	border-radius: 12px;
	background: #fff;
	width: 100%;
}

.company_card .link_button > button {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.company_card .company_title {
	width: 55%;
	padding: 24px 30px;
	border-right: 1px solid #eff0f4;
	position: relative;
}

.company_card .company_title .sponsored {
	position: absolute;
	top: 0;
	right: 0;
	background: #f5f6f8;
	padding: 6px 12px;
	border-radius: 0px 0px 0px 16px;
	text-transform: uppercase;
}

.company_card .company_title .header_block {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
}

.company_card .company_title .header_block > img {
	width: 44px;
	height: 44px;
}

.company_card .company_title .header_block .title_block {
	margin-left: 20px;
}

.company_card .company_title .header_block .title_block .title {
	display: flex;
	align-items: center;
	letter-spacing: -1px;
}

.company_card .company_title .header_block .title_block .title .sponsored {
	padding: 6px 12px;
	background: #f5f6f8;
	border-radius: 16px;
	margin-left: 12px;
}

.company_card .company_title .header_block .title_block .description {
	margin-top: 4px;
}

.company_card .company_title .footer_block .scale {
	width: 100%;
	height: 24px;
	margin: 6px 0;
	display: flex;
}

.company_card .company_info {
	flex: 1;
	padding: 24px 30px;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	border-right: 1px solid #eff0f4;
}

.company_card .company_info .info_row {
	display: flex;
	align-items: center;
}

.company_card .company_info .info_row .value {
	margin-left: 8px;
}

.company_card .company_buttons {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 24px 30px;
	row-gap: 20px;
}

.company_card .website_button {
	height: 40px;
}
.card_tooltip {
	max-width: 450px;
}
.card_tooltip :global(.ant-tooltip-inner) {
	font-size: 12px;
}

.pro_label {
	display: flex;
	gap: 6px;
	align-items: center;
	padding: 6px 14px;
	position: absolute;
	top: 0;
	right: 0;
	background: rgba(0, 165, 255, 0.15);
	border-radius: 0px 0px 0px 16px;
}
.pro_label_icon {
	width: 14px;
	height: 14px;
}
.pro_label p {
	color: #00a5ff;
	font-family: Exo;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 19.2px */
	text-transform: capitalize;
}

.card_tooltip {
	max-width: 450px;
}
.card_tooltip :global(.ant-tooltip-inner) {
	font-size: 12px;
}
