.content {
    padding: 24px 0 48px;
}

.company_info_block {
    margin-top: 40px;
    display: flex;
    border-bottom: 1px solid #EFF0F4;
}

.company_info_block .company_title_block {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 60px 24px 0;
    border-right: 1px solid #EFF0F4;
}

.title_block {
    display: flex;
    margin-bottom: 24px;
}

.title_block img {
    width: 60px;
    height: 60px;
    margin-right: 24px;
}

.title_block .title h1 {
    letter-spacing: -1px;
}

.company_info_text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;   
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
    letter-spacing: -0.5px;
}

.company_info_text.open {
    display: block;
}

.company_info_block .buttons_block {
    display: flex;
    flex-direction: column;
    width: auto;
    padding-left: 60px;
    row-gap: 30px;
}

.website_button {
    height: 48px;
    width:171px;
}

.website_button button {
    letter-spacing: -0.5px;
    font-weight: 500;
    font-size: 15px;
}

.contact_button button{
    color: #2c2e32;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.company_data_block {
    display: flex;
    border-bottom: 1px solid #EFF0F4;
}

.company_data_block .data_block {
    padding: 40px 0;
}

.company_data_block .data_block:first-child {
    min-width: 310px;
    border-right: 1px solid #EFF0F4;
}

.company_data_block .data_block:last-child {
    flex: 1;
    padding-left: 90px;
}

.company_data_block .data_block .title {
    margin-bottom: 24px;
    letter-spacing: -1px;
}

.company_data_block .data_block .info_rows {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.company_data_block .data_block .info_columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 150px;
}

.company_data_block .data_block .info_columns .info_column .column_title {
    margin: 0 0 12px 24px;
    text-transform: uppercase;
}

.company_data_block .info_row {
    display: flex;
    align-items: center;
}

.company_data_block .info_row .value {
    margin-left: 8px;
}

.read_more_button > button {
    color: #00a5ff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.focus_block {
    margin-top: 32px;
}

.focus_block .title {
    margin-bottom: 24px;
    letter-spacing: -1px;
}

.focus_block .lines_block .line_title {
    text-transform: uppercase;
    margin-bottom: 8px;
}

.focus_block .lines_block .line {
    height: 24px;
    display: flex;
    margin-bottom: 18px;
}

.focus_block .lines_block .line .part {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
}

.focus_block .lines_block .groups {
    display: flex;
    column-gap: 20px;
}

.focus_block .lines_block .groups .group {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.focus_block .lines_block .groups .group .color_block {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

/* .company_page{ */
	/* min-height: 100vh
} */