.companies_list {
	min-height: calc(100vh - var(--header-height));
	display: flex;
	flex-direction: column;
	scrollbar-gutter: stable;
	width: 100vw;
}

.content {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 0 80px;
}

.navigation_block {
	padding: 24px 0 40px;
}

.title_block {
	padding-bottom: 24px;
	letter-spacing: -1px;
}

.filters_block {
	padding-bottom: 12px;
	width: 100%;
}

.filters_block .sorting_block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.filters_block .sorting_block .firms_amount {
	letter-spacing: -0.5px;
}

.filters_block .sorting_block .sorting {
	display: flex;
	align-items: center;
}

.filters_block .sorting_block .sorting .preffix_text {
	letter-spacing: -0.5px;
}

.filters_block .sorting_block .sorting .select {
	width: 250px;
	height: 42px;
	margin-left: 12px;
}

.companies_cards {
	flex: 1 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	row-gap: 12px;
	margin-bottom: 24px;
}
.companies_card_wrapper {
	flex: 1 1;
	display: flex;

	flex-direction: column;
	row-gap: 12px;
}

.empty_content {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}
.companies_cards :global(.ant-spin-nested-loading) {
	display: flex;
	flex: 1;
	width: 100%;
}
.companies_cards :global(.ant-spin-container) {
	display: flex;
	width: 100%;
}

.filters {
	display: flex;
	align-items: stretch;
	column-gap: 8px;
	row-gap: 12px;
}

.filters_select {
	max-width: 335px;
}

.filters_select :global(.ant-select-selector) {
	padding: 0 16px 0 42px !important;
}
.filters_select :global(.ant-select-arrow) {
	inset-inline-end: 0;
	inset-inline-start: 16px;
}

.filters_select :global(.ant-select-selection-placeholder) {
	color: #949cb0;
	padding-inline-end: 0;
}
.filters_select :global(.ant-select-selection-search) {
	inset-inline-start: 42px;
	inset-inline-end: 16px;
}

.filter_item,
.filters_select,
.filter_search {
	height: 42px !important;
}

.filter_item :global(.ant-select),
.filter_search :global(.ant-select) {
	height: 42px !important;
}
.filter_search :global(.ant-input-prefix) {
	margin-inline-end: 8px;
}
.filter_search :global(.ant-select-selection-search-input) {
	padding-right: 16px !important;
}
.filter_item :global(.ant-select-selector) {
	background: linear-gradient(
			0deg,
			rgba(175, 182, 198, 0.15),
			rgba(175, 182, 198, 0.15)
		),
		#ffffff;
	border: unset !important;
	box-shadow: unset !important;
	height: 42px;
	color: #949cb0 !important;
}
:global(
		:where(.css-dev-only-do-not-override-ixblex).ant-select-dropdown
			.ant-select-item-option-selected:not(
				.ant-select-item-option-disabled
			)
			.ant-select-item-option-content
	) {
	color: #26a4ff !important;
}
.filter_item :global(.ant-select-selection-item) {
	color: #949cb0 !important;
}

.form_button {
	height: 36px;
	background: linear-gradient(
			0deg,
			rgba(38, 164, 255, 0.15),
			rgba(38, 164, 255, 0.15)
		),
		#ffffff;
	border-radius: 4px;
	transition: all 3.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.form_button span {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #26a4ff;
}
.form_button:hover {
	background: linear-gradient(0deg, #26a4ff, #26a4ff), #ffffff;
}
.form_button:hover span {
	color: #fff;
}
.form_button__light {
	background: #fff !important;
}
.form_button__light span {
	color: #26a4ff !important;
}
.tags_wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	min-height: 0px;
	transition: all 0.3s ease-out;
}
.tag {
	padding: 8px 16px;
	border-radius: 16px;
	height: 32px;
	background: linear-gradient(
			0deg,
			rgba(38, 164, 255, 0.1) 0%,
			rgba(38, 164, 255, 0.1) 100%
		),
		#fff;
	color: #26a4ff;
	font-size: 13px;
	font-family: Exo;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	display: flex;
	margin: 0;
	border: unset;
}

.tag_select {
	font-size: 13px;
	margin-right: 5px;
	font-family: Exo;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: rgba(19, 34, 62, 0.8);
}
.tag :global(.ant-tag-close-icon) {
	margin: 0px 0px 0px 12px;
}
:global(.rc-virtual-list-holder::-webkit-scrollbar) {
	width: 14px;
}
:global(.rc-virtual-list-holder::-webkit-scrollbar-thumb) {
	border: 4px solid #fff;
	background: #ececf0;
	border-radius: 12px;
}
