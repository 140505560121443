.close_button {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
    width: 20px;
    height: 20px;
}

.icon {
    fill: #949CB0;
}

.close_button:hover .icon {
    fill: #6c7180;
}