.select { width: 100%; height: 100%; }

.select :global(.ant-select-selector) { padding: 0 16px 0 42px !important; height: 40px !important; }
.select:hover :global(.ant-select-selector),
.select:focus-within :global(.ant-select-selector) { border-color: #00a5ff !important; }
.select :global(.ant-select-arrow) { inset-inline-end: 0; inset-inline-start: 16px; }

.select :global(.ant-select-selection-placeholder) { color: #949CB0; padding-inline-end: 0; }
.select :global(.ant-select-selection-search) { inset-inline-start: 42px !important; inset-inline-end: 16px; width: calc(100% - 58px) !important; }

.select_dropdown { padding: 8px 0; max-height: 300px; overflow-y: auto; border-radius: 4px; }
.select_dropdown::-webkit-scrollbar { width: 11px; }
.select_dropdown::-webkit-scrollbar-thumb { border: 2px solid #FFF; background: #ECECF0; border-radius: 12px; }
.select_dropdown .select_dropdown_content .group .title { color: #949CB0; padding: 8px 20px; }
.select_dropdown .select_dropdown_content .group .options_list .option { width: 100%; padding: 8px 20px; cursor: pointer; text-align: start; }
.select_dropdown :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled)){ background: #EAF6FF; }
.select_dropdown :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled) .ant-select-item-option-content){ color: #26A4FF;}
.select_dropdown :global( .ant-select-item-option-selected:not(.ant-select-item-option-disabled)){ font-weight: 500;}