.main_page {
	/* overflow-x: hidden; */
}
.main_page :global(.main-blue) {
	color: #26a4ff;
	font-family: "Exo", sans-serif;
}
.main_page :global(.main-gray) {
	color: #949cb0;
	font-family: "Exo", sans-serif;
}

.main_page .background {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - var(--header-height));
	background-size: cover;
}

.container {
	width: 1200px;
	margin: 0 auto;
}

.content {
	height: 100%;
}

.header {
	padding: 30px 120px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main_block {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 41px;
}

.img_block {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
}

.img_block > img {
	height: auto;
	width: 100%;
}

.title_block {
	width: 700px;
}

.title_block .title {
	margin-bottom: 40px;
	letter-spacing: -1px;
	font-family: "Exo", sans-serif;
}

.info_cases_block {
	background: #fff;
}

.info_cases {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.title {
	font-size: 70px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
}

.popular_categories .title {
	text-transform: uppercase;
	margin-bottom: 16px;
	font-family: "Exo", sans-serif;
}

.title_icon,
.title_icon svg {
	width: 36px;
	height: 36px;
	margin-right: 12px;
	fill: #26a4ff;
	color: white;
}

.title_icon_white,
.title_icon_white svg {
	width: 36px;
	height: 36px;
	margin-right: 12px;
	fill: white;
	color: white;
}

.title_icon.stroke_fill,
.title_icon.stroke_fill svg {
	stroke: #26a4ff;
}

.top_menu_buttons {
	display: flex;
	padding-right: 33px;
	font-family: "Exo", sans-serif;
	border-right: 1px solid #e9eef6;
}

.top_menu_buttons .pricing_button {
	margin-left: 24px;
	padding: 0 16px;
	height: 32px;
	border: none;
	background: none;
	box-shadow: none;
	color: #13223e;
	transition: 0.3s;
	font-size: 15px;
	font-family: "Exo", sans-serif;
	font-weight: 400;
	line-height: 32px;
}

.top_menu_buttons .pricing_button.gray {
	color: #a9b4cd;
}

.tag {
	border-radius: 24px;
	background: rgba(0, 165, 255, 0.15);
	padding: 12px 20px;
	color: #00a5ff;
	font-family: Exo;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
}
.tags_wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
@media screen and (min-width: 1920px) {
	.container {
		width: 1650px;
	}
	.title {
		font-size: 78px;
	}
	.img_block > img {
		width: 92%;
	}
}
