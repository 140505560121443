.pricing_page {
	overflow-x: hidden;
	padding-top: 140px;
}

.pricing_page .background {
	display: flex;
	flex-direction: column;
	background-color: white;
}

.top_menu_buttons {
	display: flex;
	padding-right: 33px;
	border-right: 1px solid #e9eef6;
}

.top_menu_buttons .pricing_button {
	font-family: "Exo", sans-serif;
	margin-left: 24px;
	padding: 0 16px;
	height: 32px;
	border: none;
	background: none;
	box-shadow: none;
	color: #13223e;
	transition: 0.3s;
	font-size: 15px;
	font-weight: 400;
	line-height: 32px;
}

.top_menu_buttons .pricing_button.gray {
	color: #a9b4cd;
}

.pricing_page .content .page_title {
	margin-top: 25px;
	text-align: center;
	letter-spacing: -1px;
	font-family: "Exo", sans-serif;
	margin-bottom: 60px;
	font-size: 78px;
	font-weight: 600;
	line-height: 120%;
}

.pricing_page .content .amount_block {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
}

.pricing_page .content .amount_block .title {
	margin-right: 12px;
	letter-spacing: -0.5px;
	font-family: "Exo", sans-serif;
}

.pricing_page .content .amount_block .amount_input {
	width: 105px;
	height: 44px;
	color: #26a4ff;
	font-size: 24px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	font-family: "Exo", sans-serif;
}

.pricing_page .content .period_block {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pricing_page .content .period_block .switch {
	margin: 0 10px;
	background: #26a4ff !important;
}

.pricing_page .content .period_block .period_name {
	flex: 1;
	font-family: "Exo", sans-serif;
}

.pricing_page .content .period_block .period_name.selected {
	font-weight: 500;
	color: #26a4ff;
	font-family: "Exo", sans-serif;
}

.pricing_page .content .period_block .period_name.annually {
	text-align: right;
}

.pricing_page .content .period_block .period_name.monthly {
	text-align: left;
}

.pricing_page .content .discount_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.pricing_page .content .discount_info {
	display: flex;
	justify-content: center;
}

.pricing_page .content .discount_info > p {
	background: #6de9c3;
	color: #254a59;
	padding: 10px 20px;
	border-radius: 24px;
	font-family: "Exo", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
}

.pricing_page .content .cards_grid {
	width: 100%;
	display: flex;
	justify-content: center;
}

.pricing_page .content .cards_block {
	margin: 60px 0 120px;
	display: grid;
	grid-template-columns: 300px 300px 300px;
	filter: drop-shadow(6px 6px 20px rgba(0, 27, 107, 0.07));
}

.pricing_page .content .cards_block .card {
	background: #f3f4f7;
	border: 1px solid #e9eef6;
	overflow: hidden;
}

.pricing_page .content .cards_block .card:first-child {
	border-radius: 12px 0 0 12px;
}

.pricing_page .content .cards_block .card:last-child {
	border-radius: 0 12px 12px 0;
}

.pricing_page .content .cards_block .card.active {
	border: 1px solid #26a4ff;
}
.pricing_page .content .cards_block .card.disabled {
	border: 1px solid rgb(175, 182, 198);
}
