.case_button {
    padding: 30px 40px;
    border-radius: 24px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.case_button:hover {
    background: #f1fafd;
}

.case_button:hover .case_title_block .case .case_title {
    color: #00a5ff;
}

.case_button .description {
    text-align: start;
    letter-spacing: -0.5px;
}

.case_button .case_title_block {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.case_button .case_title_block .case {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.case_button .case_title_block .case .case_title {
    margin-left: 8px;
    letter-spacing: -0.5px;
}

