.blocks_wrapper {
	display: flex;
	gap: 125px;
	flex: 1;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: calc(100vh - var(--header-height));
}

.description_block {
	width: 600px;
	display: flex;
	flex-direction: column;
}
.description_block .header {
	color: #2c2e32;
	font-family: Exo;
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 30px;
}
.description_block .description {
	color: rgba(44, 46, 50, 0.7);
	font-family: Exo;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.options {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 60px 0px;
}
.option {
	display: flex;
	align-items: center;
	gap: 4px;
}
.option .title {
	color: rgba(44, 46, 50, 0.7);
	font-family: Exo;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}
/* /______________ */

.form_block {
	display: flex;
	width: 660px;
	padding: 30px 0px;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	gap: 30px;
	height: 732px;

	border-radius: 12px;
	border: 1px solid #e7edff;
	background: #fff;

	/* open menu shadow */
	box-shadow: 0px 6px 20px 0px rgba(0, 27, 107, 0.07);
}
.form_block .title {
	color: #2c2e32;
	text-align: center;
	font-family: Exo;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}
.form_block .form {
	width: 500px;
}
@media screen and (min-width: 1920px) {
	.blocks_wrapper {
		gap: 195px;
		padding-top: 120px;
	}
}
