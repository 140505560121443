.card_content {
	height: 100%;
}

.card_content .card_title {
	line-height: 60px;
	width: 100%;
	text-align: center;
	margin-bottom: 0px;
	font-family: "Exo", sans-serif;
}

.card_content .white_background {
	background: #FFF;
	height: 100%;
}

.card_content .white_background .pricing_block {
	height: 150px;
	padding: 24px 30px;
	display: flex;
	flex-direction: column;
}

.card_content .white_background .pricing_block :global(.ant-typography) {
	letter-spacing: -1.5px;
	color: #13223E;
	text-align: center;
	margin-bottom: 14px;
	font-size: 40px;
	font-weight: 500;
	line-height: 48px;
	font-family: "Exo", sans-serif;
}

.card_content .white_background .pricing_block .info_block>p {
	text-align: center;
	font-family: "Exo", sans-serif;
}

.card_content .white_background .pricing_block .link_block {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.card_content .white_background .pricing_block .link_block .link {
	text-decoration: underline;
	color: #00a5ff;
	font-family: "Exo", sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.card_content .white_background .button_block {
	display: flex;
	justify-content: center;
	font-family: "Exo", sans-serif;
}

.card_content .white_background .button_block .button>button {
	width: auto;
	height: 38px;
	padding: 0 24px;
	font-size: 14px;
	font-weight: 500;
	line-height: 38px;
	letter-spacing: -0.5px;
	font-family: "Exo", sans-serif;
}

.card_content .white_background .options_block {
	margin-top: 30px;
	border-top: 1px solid #E9EEF6;
	padding: 30px;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 16px;
}

.card_content .white_background .options_block .option {
	display: flex;
	align-items: center;
}

.card_content .white_background .options_block .option>p {
	margin-left: 12px;
	font-family: "Exo", sans-serif;
}

.card_content.active .card_title {
	background: #00a5ff;
	color: #FFF;
	font-family: "Exo", sans-serif;
}
.card_content.disabled .card_title {
	background: rgb(175, 182, 198);
	color: #FFF;
	font-family: "Exo", sans-serif;
}