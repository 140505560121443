.dropdown_root {
	width: 100%;
	height: 100px;
	position: absolute;
	/* top: 108px!important; */
}

.dropdown_button {
	display: flex;
	align-items: center;
	padding: 0;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
}

.dropdown_button .button_title {
	color: #2c2e32;
	margin-right: 8px;
	transition: 0.3s;
}

.dropdown_button.gray .button_title {
	color: #a9b4cd !important;
}

.dropdown_button .arrow_icon {
	transition: 0.3s;
}

.dropdown_button.dropdown_opened .arrow_icon {
	transform: rotate(180deg);
}

.dropdown {
	border-top: 1px solid #e9eef6;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	height: auto;
	background: #fff;
	margin-top: 29px;
	padding: 80px 0px;
	box-shadow: 6px 6px 20px rgba(0, 27, 107, 0.07);
}

.dropdown .cases {
	margin-top: 10px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 1200px;
}

.dropdown_info {
	margin-top: 10px;
}

.dropdownInfo_wrapper {
	letter-spacing: -0.5px;
	padding-left: 40px;
	padding-right: 40px;
	max-width: 1100px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.dropdown:global(.solutions-dropdown) {
	/* margin-top: 24px; */
	padding-bottom: 60px;
}
.dropdown:global(.solutions-dropdown) .dropdownInfo_wrapper {
	padding: 0px;
}
.dropdown:global(.solutions-dropdown) .cases {
	margin-top: 28px;
	grid-template-columns: 1fr;
}

@media screen and (min-width: 1920px) {
	.dropdownInfo_wrapper {
		max-width: 1650px;
	}
	.dropdown .cases {
		max-width: 1650px;
	}
}
