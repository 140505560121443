.info_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 100px;
    padding: 140px 0 0;
}

.info_block .description {
    margin-top: 20px;
}

.img_block {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.img_block > .screenshot {
    width: 100%;
    height: auto;
}

.img_block > .second_screenshot {
    position: absolute;
    height: auto;
}

.more_button {
    margin-top: 40px;
    width: 161px;
    height: 48px;
}

.title {
    display: flex;
    align-items: center;
    letter-spacing: -1px;
}

.description {
    letter-spacing: -0.5px;
}

.custom_position_img {
	position: absolute;
}

.title_block {
    flex: 1;
}

.info_block.left .screenshot { order: 1; }
.info_block.left .title_block { order: 2; }

.info_block.left .img_block { order: 1; }

.info_block.right .screenshot { order: 2; }
.info_block.right .title_block { order: 1; }

.info_block.right .img_block { order: 2; }
