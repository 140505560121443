.footer {
	width: 100vw;
	padding: 40px 0px;
	box-shadow: 6px 0px 20px 0px rgba(0, 27, 107, 0.07);
	border-top: 1px solid #e9eef6;
	background: #fff;
}
.footer_columns__wrapper {
	display: flex;
	justify-content: space-between;
}
.footer_container {
	display: flex;
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
	gap: 40px;
}
.footer_colum {
	display: flex;
	flex-direction: column;
	gap: 30px;
}
.footer_colum__links {
	display: flex;
	flex-direction: column;
	gap: 14px;
}
.footer_column__wrapper {
	display: flex;
	gap: 50px;
}
.footer_column__title {
	color: #13223e;
	margin: 0px;
	font-family: Exo;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	min-height: 24px;
}
.footer_container {
	display: flex;
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
	gap: 40px;
}
.footer_column__title {
	color: #13223e;
	margin: 0px;
	font-family: Exo;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}
.footer_link {
	display: flex;
	gap: 4px;
}

.footer_link__title {
	color: #13223e;
	font-family: Exo;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	transition: color 0, 2s ease;
	cursor: pointer;
}
.footer_link__link:hover,
.footer_link__title:hover {
	color: #00a5ff;
}
.footer_link__tag {
	color: #00a5ff;
	font-family: Exo;
	font-size: 8px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	text-transform: uppercase;
}
.copyright {
	text-align: center;
	color: rgba(44, 46, 50, 0.7);
	font-family: Exo;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
}

.copyright_link {
	color: #00a5ff;
}

.logo_button {
	background: none;
	border: none;
	outline: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.logo_button img {
	height: 58px;
	width: auto;
	margin-left: -14px;
}

.popular_categories {
	/* display: flex; */
	padding: 65px 0 120px;
	/* justify-content: space-between;
	gap: 31px; */
}

.popular_categories .title {
	text-transform: uppercase;
	margin-bottom: 16px;
}

@media screen and (min-width: 1920px) {
	.footer_container {
		max-width: 1650px;
	}
	.footer_column__wrapper {
		gap: 100px;
	}
}
