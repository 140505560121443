.solutions_dropdown_block {
	display: flex;
	width: 100%;
}
.solutions_dropdown_block .navigation_block {
	margin-right: 140px;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 6px;
	margin-left: -16px;
}
.solutions_dropdown_block .navigation_block .navigation_button {
	transition: 0.3s;
	height: auto;
	box-shadow: none;
	padding: 12px 16px;
	background: none;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	border-radius: 12px;
}
.solutions_dropdown_block .navigation_block .navigation_button:hover {
	background: #f1fafd;
}
.solutions_dropdown_block
	.navigation_block
	.navigation_button
	.navigation_icon {
	fill: #00a5ff;
	width: 16px;
	height: 16px;
}
.solutions_dropdown_block
	.navigation_block
	.navigation_button
	.navigation_icon_white,
.solutions_dropdown_block
	.navigation_block
	.navigation_button
	.navigation_icon_white
	svg {
	fill: white;
	width: 16px;
	height: 16px;
	color: white;
}
.solutions_dropdown_block
	.navigation_block
	.navigation_button
	.navigation_icon.stroke_fill {
	stroke: #00a5ff;
}
.solutions_dropdown_block .navigation_block .navigation_button .title {
	transition: 0.3s;
	margin-left: 8px;
}
.solutions_dropdown_block .navigation_block .navigation_button:hover .title {
	color: #00a5ff;
}

.solutions_dropdown_block .content_block {
	flex: 1;
	margin-top: 12px;
}
.solutions_dropdown_block .content_block .case_info_block .title_block {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}
.solutions_dropdown_block
	.content_block
	.case_info_block
	.title_block
	.case_title_icon {
	width: 20px;
	height: 20px;
	fill: #00a5ff;
}
.solutions_dropdown_block
	.content_block
	.case_info_block
	.title_block
	.case_title_icon_white {
	width: 20px;
	height: 20px;
	color: white;
}
.solutions_dropdown_block
	.content_block
	.case_info_block
	.title_block
	.case_title_icon.stroke_fill {
	stroke: #00a5ff;
}
.solutions_dropdown_block .content_block .case_info_block .title_block .title {
	margin-left: 8px;
}
.solutions_dropdown_block .content_block .case_info_block .case_description {
	color: #949cb0;
}
