:root {
	--header-height: 78px;
}
* {
	margin: 0;
	padding: 0;
	color: #2c2e32;
	box-sizing: border-box;
	font-family: "exo";
	scroll-behavior: smooth;
}

html {
	/* scrollbar-gutter: stable; */
}

a {
	text-decoration: none;
}

button {
	border: unset;
	background: none;
}

.container {
	width: 1200px;
	margin: 0 auto;
}

@media screen and (min-width: 1920px) {
	.container {
		width: 1650px;
	}
}

::-webkit-scrollbar-thumb {
	background-color: rgba(159, 162, 180, 0.2);
	border-radius: 100px;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	border-top-right-radius: 12px;
	background: linear-gradient(
			0deg,
			rgba(223, 224, 235, 0.15),
			rgba(223, 224, 235, 0.15)
		),
		#ffffff;
}
.fs12 {
	font-size: 12px;
}
.fs13 {
	font-size: 13px;
}
.fs14 {
	font-size: 14px;
}
.fs15 {
	font-size: 15px;
}
.fs16 {
	font-size: 16px;
}
.fs18 {
	font-size: 18px;
}
.fs20 {
	font-size: 20px;
}
.fs22 {
	font-size: 22px;
}
.fs24 {
	font-size: 24px;
}
.fs26 {
	font-size: 26px;
}
.fs34 {
	font-size: 34px;
}
.fs36 {
	font-size: 36px;
}
.fs38 {
	font-size: 38px;
}
.fs40 {
	font-size: 40px;
}
.fs42 {
	font-size: 42px;
}
.fs48 {
	font-size: 48px;
}
.fs50 {
	font-size: 50px;
}

.fw300 {
	font-weight: 300;
}
.fw400 {
	font-weight: 400;
}
.fw500 {
	font-weight: 500;
}
.fw600 {
	font-weight: 600;
}

.lh16 {
	line-height: 16px;
}
.lh18 {
	line-height: 18px;
}
.lh20 {
	line-height: 20px;
}
.lh22 {
	line-height: 22px;
}
.lh24 {
	line-height: 24px;
}
.lh26 {
	line-height: 26px;
}
.lh28 {
	line-height: 28px;
}
.lh40 {
	line-height: 40px;
}
.lh46 {
	line-height: 46px;
}
.lh44 {
	line-height: 44px;
}
.lh48 {
	line-height: 48px;
}
.lh50 {
	line-height: 50px;
}
.lh58 {
	line-height: 58px;
}
.lh60 {
	line-height: 60px;
}

.main-blue {
	color: #26a4ff;
}
.main-gray {
	color: #949cb0;
}

.ant-form-item {
	margin: 0;
	padding: 0;
}

.ant-row,
.ant-select,
.ant-select-selector,
.ant-select-selection-search,
.ant-select-selection-search-input {
	height: 100% !important;
	width: 100% !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder,
.ant-select-selection-search {
	display: flex;
	align-items: center;
}

.ant-input {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding: 0 16px;
	font-family: "Exo";
}
.ant-input-disabled {
	background-color: #fbfbfc !important;
	border-color: #dfe2e8 !important;
	color: #2c2e32 !important;
}
.ant-input::placeholder {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #949cb0;
}

textarea.ant-input {
	padding: 12px 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}
textarea.ant-input::placeholder {
	color: #949cb0;
}

.ant-radio-checked .ant-radio-inner {
	border-color: #26a4ff !important;
	background-color: #eaf6ff !important;
}
.ant-radio-inner::after {
	transform: scale(0.5) !important;
	background-color: #26a4ff !important;
}
[ant-click-animating-without-extra-node="true"]::after {
	display: none;
}

.rc-virtual-list-scrollbar-thumb::-webkit-scrollbar-thumb {
	background: linear-gradient(
			0deg,
			rgba(175, 182, 198, 0.6),
			rgba(175, 182, 198, 0.6)
		),
		#ffffff !important;
	opacity: 0.5 !important;
	border-radius: 100px !important;
	width: 5px !important;
}
