.main_page {
	overflow-x: hidden;
}

.main_page .background {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: #fff;
	background-size: 100%;
	gap: 10px;
}

.button {
	width: 99px;
	height: 38px;
	line-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	padding: 4px 8px;
	border-radius: 4px;
	cursor: pointer;
	column-gap: 12px;
	transition: 0.3s;
	background: #00a5ff;
	color: #fff;
	letter-spacing: -0.5px;
}
.button:hover {
	background: #00a5ff !important;
	color: #fff;
}
.button span {
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: -0.5px;
}
