.select_with_checkbox {
	display: flex;
	cursor: pointer;
	padding: 10px 16px;
	align-items: center;
	border-radius: 4px;
	background: linear-gradient(
			0deg,
			rgba(175, 182, 198, 0.15) 0%,
			rgba(175, 182, 198, 0.15) 100%
		),
		#fff;
	gap: 12px;
	transition: background 0.24s;
}
.select_with_checkbox:hover {
	background: linear-gradient(
			0deg,
			rgba(38, 164, 255, 0.15) 0%,
			rgba(38, 164, 255, 0.15) 100%
		),
		#fff;
}

.select_with_checkbox.active,
.select_with_checkbox:hover.active {
	background: #26a4ff;
}

.select_with_checkbox .select_title {
	color: #949cb0;
	font-size: 13px;
	font-family: Exo;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	transition: all 0.24s;
}
.select_with_checkbox:hover .select_title {
	color: #26a4ff;
}

.select_with_checkbox.active .select_title,
.select_with_checkbox:hover.active .select_title {
	color: white;
}

.icon_arrow {
	transform: rotate(0deg);
	transition: all 0.24s;
}
.icon_arrow svg path {
	color: #bcbecb !important;
}

.select_with_checkbox:hover .icon_arrow svg path {
	color: rgba(38, 164, 255, 0.6) !important;
}

.icon_arrow_active {
	transform: rotate(-180deg);
	transition: all 0.24s;
}

.icon_arrow_active svg path,
.select_with_checkbox:hover.active .icon_arrow_active svg path {
	color: rgba(255, 255, 255, 0.6) !important;
}

:global(.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover) {
	background-color: rgba(38, 164, 255, 0.07);
}
:global(
		.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item span:last-child
	) {
	color: #949cb0;
}

:global(
		.ant-dropdown
			.ant-dropdown-menu
			.ant-dropdown-menu-item:hover
			span:last-child
	) {
	color: #26a4ff;
}
:global(
		.ant-dropdown
			.ant-dropdown-menu
			.ant-dropdown-menu-item:hover
			.ant-checkbox-inner
	) {
	border-color: #26a4ff;
	/* background: linear-gradient(0deg, rgba(38, 164, 255, 0.15) 0%, rgba(38, 164, 255, 0.15) 100%), #FFF; */
}

:global(.ant-checkbox-checked .ant-checkbox-inner) {
	background-color: #26a4ff;
	border-color: #26a4ff;
}

:global(.ant-dropdown .ant-dropdown-menu) {
	overflow-y: auto;
	max-height: 250px;
}

:global(
		.ant-dropdown.dropdown_search-checkbox
			.ant-dropdown-menu
			> li:first-child
	) {
	background: white !important;
	cursor: default;
	padding-bottom: 16px;
}
:global(.ant-tag-hidden) {
	display: inline-block;
}
:global(.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content) {
	display: flex;
}
:global(.ant-dropdown-menu::-webkit-scrollbar) {
	width: 11px;
	background: #fff;
}
:global(.ant-dropdown-menu::-webkit-scrollbar-thumb) {
	border: 2px solid #fff;
	background: #ececf0;
	border-radius: 12px;
}
:global(.dropdown_checkbox) {
	flex: 1;
	font-weight: 500;
}
:global(.dropdown_checkbox .ant-checkbox-checked + span) {
	color: #26a4ff;
}

:global(.dropdown_input) {
	height: 32px;
	border-radius: 4px;
	/* border: 1px solid #FFF; */
	background: #fff;
}
:global(.dropdown_input .ant-input-prefix) {
	margin-right: 11px;
}
