.ai_block {
	padding: 25px 0px;
	background: linear-gradient(
			0deg,
			rgba(0, 165, 255, 0.1) 0%,
			rgba(0, 165, 255, 0.1) 100%
		),
		#fff;
	display: flex;
}

.ai_block__button {
	display: flex;
	padding: 15px 30px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #00a5ff;
	font-family: Exo;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	height: auto;
}

.ai_block__title {
	color: #2c2e32;
	font-size: 24px;
	font-family: Exo;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	margin-bottom: 20px;
}

.ai_block__descr {
	color: rgba(44, 46, 50, 0.7);
	font-family: Exo;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
}

.ai_block__button span {
	color: #00a5ff;
}

.ai_block__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 80px;
}

.ai_block__textWrapper {
	display: flex;
	align-items: center;
	/* flex-direction: column; */
	gap: 20px;
}

.ai_block__icon svg {
	width: 300px;
	height: 100px;
}

@media screen and (min-width: 1920px) {
	/* .ai_block__wrapper {
		gap: 200px;
	} */
	/* .ai_block__icon svg {
		width: auto;
		height: auto;
	} */
}

/* _______ */

.ai_chat .background {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - var(--header-height));
	background: white;
}
.ai_chat .wrapper {
	display: flex;
	justify-content: center;
	height: 100%;
	background: white;
}
.messages_wrapper {
	display: flex;
	flex-direction: column;
	width: 734px;
	gap: 40px;
}

/* _______ */
.ai_input__wrapper {
	border-top: 1px solid rgba(44, 46, 50, 0.15);
	padding: 30px 0px;
	width: 100vw;
	margin: 0 auto;
	background: #fff;
	box-shadow: 6px 0px 20px 0px rgba(0, 27, 107, 0.07);
}
.ai_input__button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #00a5ff;
	width: 40px !important;
	border-radius: 50px;
	cursor: pointer;
	height: 40px;
}

.form {
	display: flex;
	gap: 16px;
	justify-content: center;
}
/* __________ */
.ai_chat__message_wrapper {
	display: flex;
}
.ai_chat__icon {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}
.ai_chat__message_wrapper.own {
	display: flex;
	align-self: flex-end;
}

.ai_chat__avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #00a5ff;
	border-radius: 50%;
	margin-right: 20px;
	width: 64px;
	height: 64px;
}
.ai_chat__message {
	display: flex;
	padding: 20px;
	color: rgba(44, 46, 50, 0.7);
	background: rgba(0, 165, 255, 0.1);
	font-family: Exo;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	border-radius: 0px 12px 12px 12px;
	width: 600px;
	max-width: 600px;
}
.ai_chat__message.own {
	background: rgba(44, 46, 50, 0.05);
	border-radius: 12px 12px 0px 12px;
}
