.company_search .background {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: url("../shared/img/main-background.svg") 100% 100% no-repeat;
}

.company_search .search_block {
    padding-top: 140px;
}