.wrapper {
	padding: 24px 40px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.cards_wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.card {
	padding: 16px 20px;
	border-radius: 8px;
	border: 1px solid rgba(44, 46, 50, 0.15);
	background: #fff;
	cursor: pointer;
}
.card_header {
	display: flex;
	align-items: center;
	gap: 12px;
	margin-bottom: 10px;
}
.card_title {
	color: #2c2e32;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 20.8px */
}
.card_descr {
	color: rgba(44, 46, 50, 0.7);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	text-align: start;
}

.footer_text {
	color: rgba(44, 46, 50, 0.7);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}
