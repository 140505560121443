.navigation_block {
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
}

.redirect_button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
}