.company_contact .contact_block {
    margin: 30px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.company_contact .contact_block .form_block .company_name {
    margin-bottom: 4px;
    letter-spacing: -1px;
}

.company_contact .contact_block .form_block .description {
    margin-bottom: 40px;
    letter-spacing: -0.5px;
}

.company_contact .form_block {
    width: 586px;
    margin: 10px auto 0;
}

.company_contact :global(.ant-row) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.company_contact :global(.ant-form-item-control) {
    flex: auto;
    width: 100%;
}

.company_contact :global(.ant-row .ant-form-item-label) {
    margin-bottom: 4px;
}

.company_contact :global(.ant-row .ant-form-item-label),
.company_contact :global(.ant-row .ant-form-item-label > label) {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    height: auto;
}

.company_contact :global(.ant-row .ant-form-item-label > label::after) {
    display: none;
}

.company_contact .input {
    height: 44px;
}

.company_contact .full_name_row {
    margin-bottom: 24px;
}

.company_contact .email_row {
    margin-bottom: 2px;
}

.company_contact .email_info {
    margin-bottom: 24px;
}

.company_contact .company_info {
    display: flex;
    column-gap: 16px;
    margin-bottom: 24px;
}

.company_contact .company_info :global(.ant-form-item) {
    flex: 1;
}

.company_contact .radio_group :global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.company_contact .radio_group :global(.ant-row .ant-form-item-label) {
    margin-bottom: 16px;
}
.company_contact  :global(.ant-upload-list-item-thumbnail) {
    display: none;
}

.company_contact .textarea {
    margin: 24px 0;
}

.company_contact .attachment_button {
    display: flex;
    align-items: center;
    column-gap: 8px;
    background: none;
    border: none;
    padding: 0;
    outline: none;
}

.company_contact .attachment_button .text {
    color: #26A4FF;
}

.submit_button {
    height: 48px;
    margin: 24px 0 12px;
}

.submit_button > button {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;
}

.company_contact :global(.ant-upload-list) {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.company_contact :global(.ant-upload-list-item) {
    margin: 0 !important;
    height: 44px !important;
    padding: 0 20px !important;
}

.company_contact :global(.ant-upload-list-item-thumbnail) {
    width: 20px !important;
    height: 20px !important;
}

.company_contact :global(.ant-upload-list-item-actions) {
    display: flex;
    align-items: center;
}

.company_contact :global(.ant-upload-list-item-action) {
    width: 16px !important;
    height: 16px !important;
}

.company_contact :global(.ant-upload-list-item-action):hover {
    background: none !important;
}

.company_contact .remove_icon {
    fill: #FF0000;
    width: 16px;
    height: 16px;
}

.company_contact :global(.ant-upload-list-item-container) {
    box-shadow: 6px 6px 20px rgba(0, 27, 107, 0.07);
}