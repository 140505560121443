.popular_card {
	width: 100%;
	background: #fff;
	border: 1px solid rgba(211, 220, 236, 0.5);
	box-shadow: 6px 6px 20px rgba(0, 27, 107, 0.07);
	border-radius: 12px;
	padding: 30px 40px;
}

.popular_card .title_block {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	letter-spacing: -0.5px;
}

.popular_card .title_block .title {
	letter-spacing: -0.5px;
}

.popular_card .title_block > img {
	width: 50px;
	height: 50px;
	margin-right: 20px;
}

.popular_card .categories_list {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.popular_card .categories_list .category {
	color: #2c2e32;
}

.popular_card .categories_list .category:hover {
	color: #00a5ff;
}
