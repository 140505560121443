.primary_button button {
    width: 100%;
    height: 100%;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    column-gap: 12px;
    transition: .3s;
}

.violet button { background: #00a5ff; color: #FFF; }
.violet.empty button { background: none; border: 1px solid #00a5ff; color: #00a5ff; }
.violet:hover button { background: #00a5ff; color: #FFF; }

.blue button { background: #26A4FF; color: #FFF; }
.blue.empty button { background: none; border: 1px solid #26A4FF; color: #26A4FF; }
.blue svg { fill: #FFF; transition: .3s; }
.blue:hover button { background: #26A4FF; color: #FFF; }
.blue:hover svg { fill: #FFF; }

.no_background button { padding: 0; background: none; justify-content: flex-start; height: auto; }
.no_background:hover button { color: #26A4FF; }