.search_block {
    width: 100%;
    display: flex;
    justify-content: center;
}

.search_content {
    max-width: 960px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search_content .title {
    margin-bottom: 16px;
    text-align: center;
    letter-spacing: -1px;
}

.search_content .description {
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: -0.5px;
}

.search_content .filters_block {
    width: 100%;
    display: flex;
    align-items: center;
}

.search_content .filters_block > p {
    white-space: nowrap;
    letter-spacing: -0.5px;
}

.search_content .filters_block .looking_for_text {
    margin-right: 12px;
}

.search_content .filters_block .activity_select { width: 400px; }
.search_content .filters_block .location_select { width: 264px; }

.search_content .filters_block .in_text {
    margin: 0 12px;
}

.search_content .select { width: 100%; height: 100%; }

.search_content .select :global(.ant-select-selector) { padding: 0 16px 0 42px; height: 40px !important; }
.search_content .select :global(.ant-select-arrow) { inset-inline-end: 0; inset-inline-start: 16px; }

.search_content .select :global(.ant-select-selection-placeholder) { color: #949CB0; padding-inline-end: 0; }
.search_content .select :global(.ant-select-selection-search) { inset-inline-start: 42px; inset-inline-end: 16px; width: calc(100% - 58px) !important; }

.search_content .find_button {
    margin-left: 12px;
    width: 127px;
    height: 40px;
}

.search_content .find_button button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
}
:global(.no-validation-message .ant-form-item-explain ){
    display: none;
}