.top_menu_block {
	width: calc(100vw - 6px);
	height: var(--header-height);
	top: 0;
	position: sticky;
	z-index: 999;
}

/* .top_menu_block.border_bottom {
	height: 87px;
} */

.top_menu_block .top_menu {
	width: 100%;
	z-index: 2;
	position: relative;
}

.with_background {
	background: #fff;
}

.top_menu_block.border_bottom .top_menu {
	border-bottom: 1px solid #e9eef6;
}

.top_menu .content {
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top_menu .logo_button {
	background: none;
	border: none;
	outline: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
}

.top_menu .logo_button img {
	height: 58px;
	width: auto;
	margin-left: -14px;
}
.top_menu .logo_button svg {
	height: 71px;
	top: -32px;
	left: -1px;
	width: auto;
	position: absolute;
	/* margin-left: -14px; */
}

.top_menu .buttons_block {
	display: flex;
	align-items: center;
}

.top_menu .pricing_button {
	margin-left: 24px;
	padding: 0 16px;
	height: 32px;
	border: none;
	background: none;
	box-shadow: none;
	color: #13223e;
	transition: 0.3s;
	font-size: 15px;
	font-family: "Exo", sans-serif;
	font-weight: 400;
	line-height: 32px;
	cursor: pointer;
	border: unset !important;
}

.top_menu .pricing_button.gray {
	color: #a9b4cd;
}

.dropdown_buttons {
	display: flex;
	align-items: center;
	padding-right: 33px;
	border-right: 1px solid #e6ecf6;
}

/* .dropdown_buttons .dropdown_button {
	margin-right: 40px;
} */

.started_buttons {
	display: flex;
	align-items: center;
}
.border {
	width: 1px;
	height: 30px;
	background: #e6ecf6;
}

.started_buttons .login_button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 99px;
	border: none;
	padding: 0;
	outline: none;
	cursor: pointer;
	color: #00a5ff;
	letter-spacing: -0.5px;
}

.started_buttons .get_started_button {
	width: 77px;
	height: 40px;
	line-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	padding: 12px 24px;
	border-radius: 6px;
	cursor: pointer;
	column-gap: 12px;
	transition: 0.3s;
	background: #00a5ff;
	color: #fff;
	letter-spacing: -0.5px;
}

.section_button {
	margin-right: 40px;
	transition: 0.3s;
}

.section_button.gray {
	color: #a9b4cd;
}

.section_button:hover {
	color: #00a5ff;
}

:global(.ant-modal .ant-modal-content) {
	padding: 0px;
}
:global(.ant-modal .ant-modal-header) {
	padding: 15px 24px;
	background: #f6f7fb;
	margin-bottom: 0px;
}
:global(.ant-modal .ant-modal-title) {
	color: #13223e;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}
:global(.ant-modal .ant-modal-close:hover) {
	background-color: transparent;
}
:global(.ant-modal .ant-modal-close-x svg) {
	color: #afbbe4;
}

:global(.ant-modal .ok-button) {
	padding: 12px 24px;
	border-radius: 6px;
	background: #00a5ff;
	cursor: pointer;
	width: auto;
	border: unset;
	transition: all 0.2s ease;

	color: #fff;
	text-align: center;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px; /* 123.077% */
	letter-spacing: -0.1px;
}
:global(.ant-modal .ok-button:hover) {
	background: #2595fd;
}
